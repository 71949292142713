import React from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo';

import Layout from '../components/layout'
import Container from '../components/Container'
import Heading from '../components/Heading'
import Hero from '../components/Hero'
import Listing from '../components/listing'
import Box from '../components/Box'
import Flex from '../components/Flex'
import ShowAll from '../components/ShowAll'
import Grid from '../components/Grid'
import Header from '../components/header'

const IndexPage = ({ data }) => {
  const { totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} job${
    totalCount === 1 ? '' : 's'
  } in total`;
  return (
  <Layout>
    <SEO title='Home' /> 
    <Header />
    <Hero title="Find the best jobs in exciting startups." />
    <Container as="main" id='main-content'>
      
      <Grid gridTemplateColumns={['none', '1fr']} gridGap={[0, 4]}>

        <Box as="section">
          <div css="border-bottom: solid 2px #ebebeb">
            <Flex justifyContent="space-between" alignItems="center" mb="3">
              <Heading as="h2" fontSize="3" fontWeight="500" pb="0">{tagHeader}</Heading>
              <ShowAll><Link to="/tags">Show filters</Link></ShowAll>
            </Flex>
          </div>
          <Box as="ul" p="0">
            <Listing />
          </Box>
        </Box>

      </Grid>

    </Container>
  </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      totalCount
    }
  }
`;
